@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@400;600;700&family=Roboto:wght@300&display=swap');

html,
body {
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  color: #1b2241;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
font-family: 'Lora', serif;
font-family: 'Roboto', sans-serif; 
*/

h1,
h2,
h3 {
  font-family: 'Lora', serif;
}

.nav-color .ant-menu-light.ant-menu-submenu > .ant-menu {
  background-color: #1b2241d2;
}

.ant-menu-light.ant-menu-submenu > .ant-menu {
  /* background-color: transparent; */
  backdrop-filter: blur(2px);
  background-color: #1b224164;
  color: #fff;
  box-shadow: none;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: transparent;
  color: #fff;

  border-radius: 0;
}

.ant-menu-light > .ant-menu .ant-menu-item-selected {
  border-bottom: 2px solid #fff;
}

.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-light
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active {
  color: #fff;
}

.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-light
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active {
  color: #fff;
}

.ant-menu-light
  .ant-menu-item:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ):hover,
.ant-menu-light
  > .ant-menu
  .ant-menu-item:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ):hover {
  color: #fff;
}

.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-light
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: #1b22417a;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
  border-bottom-color: #fff;
}

.ant-drawer .ant-drawer-close {
  color: #fff;
}

.ant-menu-horizontal {
  border: 0;
}

[class^='ant-menu'] [class^='ant-menu'],
[class*=' ant-menu'] [class^='ant-menu'] {
  color: #fff;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-active::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-active::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-color: #fff;
}

.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item {
  min-height: 40px;
  height: auto;
}

.down-arrow {
  position: absolute;
  top: calc(100% - 90px);
  bottom: 20px;
  left: calc(50% - 0px);
  width: 0;
  height: 60px;
  border: 1px solid #fff;
  animation: jumpInfinite 1.5s infinite;
}

.down-arrow:after {
  content: ' ';
  position: absolute;
  top: 41px;
  left: -8.5px;
  width: 16px;
  height: 16px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  border-radius: 2px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-menu-light:not(
    .ant-menu-horizontal
  )
  .ant-menu-item:not(.ant-menu-item-selected):hover,
:where(.css-dev-only-do-not-override-p7e5j5).ant-menu-light
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: #1b22419d;
}

.sub {
  font-style: italic;
  font-weight: 200;
}
